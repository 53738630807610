<template>
  <div id="hub"></div>
</template>

<script>
import CrossStorage from "cross-storage";
let CrossStorageHub = CrossStorage.CrossStorageHub;
CrossStorageHub.init([
  { origin: /localhost/, allow: ["get", "set"] },
  { origin: /.*192.168.31.*?$/, allow: ["get", "set"] },
  { origin: /.*172.20.10.*?$/, allow: ["get", "set"] },
  // { origin: /.*\w+.sshlqf.com.*$/, allow: ["get", "set"] },
  { origin: /.*\w+.bzoe.com.cn.*$/, allow: ["get", "set"] }
])
export default {
  name: "Hub"
}
</script>

<style scoped></style>
